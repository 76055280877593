import { Helmet } from "react-helmet";

export default function Footer()
{
    return (
        <>
            <Helmet>
                <style type="text/css">{`
                    @media (max-width: 1000px)
                    {
                        footer
                        {
                            flex-direction: column !important;
                        }
                        footer > #footer-cell-3
                        {
                            justify-content: flex-start !important;
                        }
                    }
                `}</style>
            </Helmet>
            
            <footer
                style=
                {{
                    color: "white",
                    backgroundColor: "#181A2A",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "auto",
                    padding: "10px clamp(20px, calc(50% - 250px), 50px) 10px clamp(20px, calc(50% - 250px), 50px)",
                    margin: 0,
                    overflow: "hidden"
                }}
            >
                <div
                    id="footer-cell-1"
                    style=
                    {{
                        whiteSpace: "nowrap",
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: 0
                    }}
                >
                    <p
                        style=
                        {{
                            fontSize: "medium",
                            wordSpacing: "normal",
                            padding: 0
                        }}
                    >
                        <b>
                            © Bluesat UNSW 2024 {/* Change when next year arrives. */}
                            <br />
                            Est. 1997
                        </b>
                    </p>
                </div>
                <div
                    id="footer-cell-2"
                    style=
                    {{
                        display: "flex",
                        flex: 0,
                        flexDirection: "row"
                    }}
                >
                    <a href="https://www.unsw.edu.au" rel="noreferrer" target="_blank" style={{ display: "block", padding: 0, margin: 0 }}>
                        <img
                            src="/static/media/unsw_sydney_crest.png"
                            alt="UNSW Sydney Crest"
                            style={{ display: "block", padding: 20, height: "max(120px, 14vh)" }}
                        />
                    </a>
                    <a href="https://www.arc.unsw.edu.au/get-involved/opportunity?name=Bluesat" rel="noreferrer" target="_blank" style={{ display: "block", padding: 0, margin: 0 }}>
                        <img
                            src="/static/media/arc_clubs_logo_white.png"
                            alt="Arc Clubs Logo"
                            style={{ display: "block", padding: 20, height: "max(120px, 14vh)" }}
                        />
                    </a>
                </div>
                <div
                    id="footer-cell-3"
                    style=
                    {{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: 0
                    }}
                >
                    <a
                        href="mailto:bluesat@unsw.edu.au"
                        target="_blank"
                        style={{ color: "white", display: "block", padding: 5 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            className="fill-current"
                            style={{ width: "max(25px, 3.2vh)", height: "max(25px, 3.2vh)" }}
                        >
                            <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"></path>
                        </svg>
                    </a>
                    <a
                        href="https://www.youtube.com/@BLUEsatAuUNSW/"
                        target="_blank"
                        style={{ color: "white", display: "block", padding: 5 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            className="fill-current"
                            style={{ width: "max(25px, 3.2vh)", height: "max(25px, 3.2vh)" }}
                        >
                            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                        </svg>
                    </a>
                    <a
                        href="https://www.facebook.com/bluesat.unsw/"
                        target="_blank"
                        style={{ color: "white", display: "block", padding: 5 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            className="fill-current"
                            style={{ width: "max(25px, 3.2vh)", height: "max(25px, 3.2vh)" }}
                        >
                            <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                        </svg>
                    </a>
                    <a
                        href="https://discord.gg/kVAyKWWDSH"
                        target="_blank"
                        style={{ color: "white", display: "block", padding: 5 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 15.8 15.8"
                            className="fill-current"
                            style={{ width: "max(25px, 3.2vh)", height: "max(25px, 3.2vh)" }}
                        >
                            <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"></path>
                        </svg>
                    </a>
                    <a
                        href="https://www.instagram.com/unsw_bluesat"
                        target="_blank"
                        style={{ color: "white", display: "block", padding: 5 }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 15.8 15.8"
                            className="fill-current"
                            style={{ width: "max(25px, 3.2vh)", height: "max(25px, 3.2vh)" }}
                        >
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"></path>
                        </svg>
                    </a>
                </div>
            </footer>
        </>
    );
}