export default function Nav()
{
    return (
        <nav style={{ height: "auto", padding: 0 }}>
            <div
                id="banner-bg"
                style=
                {{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                    height: "auto",
                    padding: "10px clamp(10px, calc(50% - 250px), 40px) 10px clamp(10px, calc(50% - 250px), 40px)"
                }}
            >
                <button style=
                {{ marginRight: "auto", height: "100%" }}>
                    <a
                        id="bluesat-logo-button"
                        href="./"
                        style={{ display: "block", height: "max(40px, calc(6vh - 20px))" }}
                    >
                        <img
                            id="bluesat-logo-blue"
                            src="/static/media/bluesat_logo_dark_text.svg"
                            alt="Bluesat Logo"
                            height={40}
                            style={{ display: "block", height: "100%" }}
                        />
                    </a>
                </button>
                <button
                    id="contact-button"
                    style=
                    {{
                        color: "#181A2A",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%"
                    }}
                >
                    <a
                        href="./contact"
                        style=
                        {{
                            color: "inherit",
                            fontSize: "1.5rem",
                            display: "block"
                        }}
                    >
                        <b>Contact Us</b>
                    </a>
                </button>
            </div>
            <div id="highlight-bar" style={{ backgroundColor: "#181A2A", width: "100%", height: 5 }} />
        </nav>
    );
}