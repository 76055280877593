import Helmet from "react-helmet";

import Nav from "./elements/nav";
import Footer from "./elements/footer";

export default function NotFound()
{
    return (
        <>
            <Helmet>
                {/* HTML Meta Tags */}
                <title>404 | Bluesat UNSW</title>
                <meta name="description" content="Page not found!" />

                {/* OpenGraph Meta Tags */}
                <meta property="og:url" content="https://bluesat.unsw.edu.au" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="404 | Bluesat UNSW" />
                <meta property="og:description" content="Page not found!" />
                <meta property="og:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="bluesat.unsw.edu.au" />
                <meta property="twitter:url" content="https://bluesat.unsw.edu.au" />
                <meta name="twitter:title" content="404 | Bluesat UNSW" />
                <meta name="twitter:description" content="Page not found!" />
                <meta name="twitter:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />
            </Helmet>
            <Nav />
            <main
                style=
                {{
                    backgroundImage: 'url("/static/media/landing_background_upscaled.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    padding:
                        "10px clamp(20px, calc(50% - 250px), 50px) 10px clamp(20px, calc(50% - 250px), 50px)",
                    margin: 0
                }}
            >
                <div id="heading" style={{ width: "fit-content" }}>
                    <div
                        style=
                        {{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            margin: "auto 0 auto 0"
                        }}
                    >
                        <h1
                            style=
                            {{
                                fontSize: "calc(2.8vw + 2.8vh * 16 / 9)",
                                padding: 0,
                                margin: "0 auto 0 auto"
                            }}
                        >
                            Page Not Found
                        </h1>
                    </div>
                    <div
                        style=
                        {{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            margin: "auto 0 auto 0"
                        }}
                    >
                        <h1
                            style=
                            {{
                                fontSize: "calc(1.8vw + 1.8vh * 16 / 9)",
                                padding: 0,
                                margin: "0 auto 0 auto"
                            }}
                        >
                            404
                        </h1>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}