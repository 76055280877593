import { useEffect } from "react";
import $ from "jquery";
import Helmet from "react-helmet";

import Nav from "./elements/nav";
import Footer from "./elements/footer";

export default function Index()
{
    useEffect(() =>
    {
        // Dynamic Elements Code v

        $("body").off("scroll mousewheel touchmove");

        // Add all the dynamic elements of the page; by design, original HTML works without js.
        $("#bluesat-logo-button").append(`<img id="bluesat-logoWhite" src="/static/media/bluesat_logo_light_text.svg" alt="Bluesat Logo" height="40" aria-hidden="true" style="display: block; position: absolute; top: 0; left: 0; height: 100%;" />`);
        $("#bluesat-logo-blue").css("opacity", "0%");
        $("#contact-button").css("color", "white");
        $("nav").css("background-color", "transparent");
        $("nav").css("background-image", "url('/static/media/landing_background_upscaled.png')");
        $("nav").css("background-repeat", "no-repeat");
        $("nav").css("background-position", "top");
        $("nav").css("background-size", "cover");
        $("nav").css("background-attachment", "fixed");
        $("#banner-bg").css("background-color", "transparent");
        $("#heading").css("transform", "translate(0px, 0px)");
        $("#heading").css("opacity", "100%");
        $("#highlight-bar").remove(); // Remove default nav highlight bar, since we add a dynamic one.
    
        // Add transition timers to dynamic elements.
        // Timeout is so jQuery won't flicker elements (iirc).
        setTimeout(() =>
        {
            $("#bluesat-logo-blue").css("transition", "opacity 1s");
            $("#bluesat-logoWhite").css("transition", "opacity 1s");
            $("#contact-button").css("transition", "color 1s");
            $("#banner-bg").css("transition", "background-color 1s");
            $("#heading").css("transition", "transform 1s, opacity 1s");
            $("header").css("transition", "height 1s");
        }, 1);
    
        // Dynamic highlight bar / divider.
        $("#article").prepend(`<div id="divider" style="background-color: #181A2A; z-index: 1337; position: sticky; top: ${$("nav").height()}px; width: 100%; height: 5px;"></div>`);
        $(window).on("resize", () => // Set divider position to bottom of nav bar when position: fixed.
        {
            $("#divider").css("top", `${$("nav").height()}px`);
        });
        
        var scrollOld = 0, scrollNew = 0;
        setInterval(() =>
        {
            scrollNew = $(window).scrollTop() ?? 0;

            var scrollPosNew = ($("header").outerHeight() ?? 0) - scrollNew - ($("nav").outerHeight() ?? 0);
            var scrollPosOld = ($("header").outerHeight() ?? 0) - scrollOld - ($("nav").outerHeight() ?? 0);
        
            if (scrollNew > 0 && scrollOld === 0)
                $(window).scrollTop(($("header").outerHeight() ?? 0) - ($("nav").outerHeight() ?? 0) + 1);
            if (scrollPosNew >= 0 && scrollPosOld < 0)
            {
                $("#heading").css("transform", "translate(0px, 0px)");
                $("#heading").css("opacity", "100%");
                
                $("#bluesat-logo-blue").css("opacity", "0%");
                $("#bluesat-logoWhite").css("opacity", "100%");
                $("#contact-button").css("color", "white");
                $("#banner-bg").css("background-color", "transparent");

                $(window).scrollTop(0);
            }
            else if (scrollPosNew < 0 && scrollPosOld >= 0)
            {
                $("#heading").css("transform", `translate(0px, -${$("#heading").height()}px`);
                $("#heading").css("opacity", "0%");

                $("#bluesat-logo-blue").css("opacity", "100%");
                $("#bluesat-logoWhite").css("opacity", "0%");
                $("#contact-button").css("color", "#181A2A");
                $("#banner-bg").css("background-color", "white");
            }

            scrollOld = scrollNew;
        }, 20);
        $("header").on("click", () =>
        {
            if (($(window).scrollTop() ?? 0) <= ($("header").outerHeight() ?? 0) - ($("nav").outerHeight() ?? 0))
                $(window).scrollTop(($("header").outerHeight() ?? 0) - ($("nav").outerHeight() ?? 0) + 1);
        });

        // The Gram(TM) Showcase Carousel v / Dynamic Elements Code ^

        const navArrowFadeOutTime = 200;

        $("#showcase-container").html
        (`
<h2>Bluesat Lore Drops</h2>
<div
    id="showcase-carousel-container"
    style="
width: 100%;
position: relative;"
>
    <div
        id="showcase-carousel"
        style="
display: flex;
flex-direction: row;
position: relative;
width: 100%;
padding: 0px 5px 0px 5px;
overflow: hidden;"
    >
    </div>
    <div class="carousel-nav-button" style="border-radius: 50%; opacity: 100%; display: flex; justify-content: center; align-items: center; position: absolute; bottom: 50%; left: 20px; width: max(25px, calc(100% / 4 / 15)); aspect-ratio: 1 / 1; padding: 0;">
        <img src="/static/landing/nav_left_arrow.svg" alt="Navigation Arrow Left" width="40" height="40" style="color: white; display: block; width: 100%; height: 100%; padding: 0; margin: 0;" />
    </div>
    <div class="carousel-nav-button" style="border-radius: 50%; opacity: 100%; display: flex; justify-content: center; align-items: center; position: absolute; bottom: 50%; right: 20px; width: max(25px, calc(100% / 4 / 15)); aspect-ratio: 1 / 1; padding: 0;">
        <img src="/static/landing/nav_right_arrow.svg" alt="Navigation Arrow Right" width="40" height="40" style="color: white; display: block; width: 100%; height: 100%; padding: 0; margin: 0;" />
    </div>
</div>`
        );

        var scrollPos = 0;
        var canCarouselNav = true;
        $("#showcase-carousel-container").children(".carousel-nav-button").eq(0).on("click", () =>
        {
            if (canCarouselNav)
            {
                canCarouselNav = false;
                var prevScrollPos = $("#showcase-carousel").scrollLeft() ?? 0;
                $("#showcase-carousel").scrollLeft(($("#showcase-carousel").scrollLeft() ?? 0) - Math.min(Math.floor(($("#showcase-carousel").innerWidth() ?? 0) / ($(".carousel-item").outerWidth() ?? 0)), 2) * ($(".carousel-item").outerWidth() ?? 0));
                setTimeout(() =>
                {
                    var checkCarouselNav = setInterval(() =>
                    {
                        var curScrollPos = $("#showcase-carousel").scrollLeft() ?? 0;
                        if (curScrollPos == prevScrollPos)
                        {
                            clearInterval(checkCarouselNav);
                            canCarouselNav = true;
                        }
                        else prevScrollPos = curScrollPos;
                    }, 20);
                }, 20);
            }
        });
        $("#showcase-carousel-container").children(".carousel-nav-button").eq(1).on("click", () =>
        {
            if (canCarouselNav)
            {
                canCarouselNav = false;
                var prevScrollPos = $("#showcase-carousel").scrollLeft() ?? 0;
                $("#showcase-carousel").scrollLeft(($("#showcase-carousel").scrollLeft() ?? 0) + Math.min(Math.floor(($("#showcase-carousel").innerWidth() ?? 0) / ($(".carousel-item").outerWidth() ?? 0)), 2) * ($(".carousel-item").outerWidth() ?? 0));
                setTimeout(() =>
                {
                    var checkCarouselNav = setInterval(() =>
                    {
                        var curScrollPos = $("#showcase-carousel").scrollLeft() ?? 0;
                        if (curScrollPos == prevScrollPos)
                        {
                            clearInterval(checkCarouselNav);
                            canCarouselNav = true;
                        }
                        else prevScrollPos = curScrollPos;
                    }, 20);
                }, 20);
            }
        });
        setInterval(() =>
        {
            if ($("#showcase-carousel").scrollLeft() === 0)
                $(".carousel-nav-button").eq(0).fadeOut(navArrowFadeOutTime);
            else $(".carousel-nav-button").eq(0).fadeIn(navArrowFadeOutTime);
            if ($("#showcase-carousel").scrollLeft() === $("#showcase-carousel").prop("scrollWidth") - ($("#showcase-carousel").outerWidth() ?? 0))
                $(".carousel-nav-button").eq(1).fadeOut(navArrowFadeOutTime);
            else $(".carousel-nav-button").eq(1).fadeIn(navArrowFadeOutTime);
        }, 200);

        for (var i = 0; i < 10; i++)
        $("#showcase-carousel").append
        (`
<div
    class="carousel-item"
    style="
padding: 0px 5px 10px 5px;
flex-shrink: 0;
display: flex;
flex-direction: column;
min-width: min(100%, 250px);
max-width: calc(100% / 4);
overflow: hidden;"
>
    <div
        style="
background: rgba(0, 0, 0, 0);
background-image: linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(24, 26, 42, 1) 100%), url('/static/media/bluesat_logo_dark_full.svg');
background-position: center top;
background-size: cover;
background-repeat: no-repeat;
background-clip: content-box;
border-radius: 20px;
aspect-ratio: 1 / calc(sqrt(2));
padding: 0;
margin: 0;"
    >
        <h4 style="position: relative; top: 50%">beans</h4>
        <p style="color: white; text-overflow: ellipsis; flex: 1; position: relative; top: 50%; bottom: 0; width: 100%; overflow: hidden;">The Industrial Revolution and its consequences have been a disaster for the human race. They have greatly increased the life-expectancy of those of us who live in “advanced” countries, but they have destabilized society, have made life unfulfilling, have subjected human beings to indignities, have led to widespread psychological suffering (in the Third World to physical suffering as well) and have inflicted severe damage on the natural world.</p>
    </div>
</div>`
        );
    }, []);

    // The Gram(TM) Showcase Carousel ^

    return (
        <>
            <Helmet>
                {/* HTML Meta Tags */}
                <title>Home | Bluesat UNSW</title>
                <meta name="description" content="Welcome to Bluesat." />

                {/* OpenGraph Meta Tags */}
                <meta property="og:url" content="https://bluesat.unsw.edu.au" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Home | Bluesat UNSW" />
                <meta property="og:description" content="Welcome to Bluesat." />
                <meta property="og:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="bluesat.unsw.edu.au" />
                <meta property="twitter:url" content="https://bluesat.unsw.edu.au" />
                <meta name="twitter:title" content="Home | Bluesat UNSW" />
                <meta name="twitter:description" content="Welcome to Bluesat." />
                <meta name="twitter:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />

                <link rel="stylesheet" href="/static/landing/landing.css" />
                <style type="text/css">{`
                    #root
                    {
                        display: block !important;
                        flex-direction: unset !important;
                    }
                    nav
                    {
                        z-index: 2;
                        position: fixed;
                        inset: 0 0 auto 0;
                    }
                    #bluesat-logo-button
                    {
                        position: relative;
                    }
                `}</style>
            </Helmet>
            <Nav />
            <header
                style=
                {{
                    zIndex: 1,
                    backgroundImage: 'url("/static/media/landing_background_upscaled.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    position: "relative",
                    height: "100%",
                    padding: "10px clamp(20px, calc(50% - 250px), 50px) 10px clamp(20px, calc(50% - 250px), 50px)"
                }}
            >
                <div id="heading" style={{ width: "fit-content" }}>
                    <div
                        style=
                        {{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            margin: "auto 0 auto 0"
                        }}
                    >
                        <h1
                            style=
                            {{
                                fontSize: "calc(2.8vw + 2.8vh * 16 / 9)",
                                textShadow: "#00000044 0.08em 0.08em",
                                padding: 0,
                                margin: "auto",
                            }}
                        >
                            Student Space Projects
                        </h1>
                    </div>
                    <div
                        style=
                        {{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            margin: "auto 0 auto 0"
                        }}
                    >
                        <h1
                            style=
                            {{
                                fontSize: "calc(1.8vw + 1.8vh * 16 / 9)",
                                textShadow: "#00000044 0.08em 0.08em",
                                padding: 0,
                                margin: "0 auto 0 auto"
                            }}
                        >
                            Bluesat UNSW
                        </h1>
                    </div>
                </div>
            </header>
            <div
                id="article"
                style={{ zIndex: 0, flex: 1, position: "relative", width: "100%", height: "auto" }}
            >
                <main
                    style=
                    {{
                        width: "100%",
                        height: "100%",
                        padding: "80px clamp(10px, calc(50% - 250px), 200px) 10px clamp(10px, calc(50% - 250px), 200px)",
                        margin: 0
                    }}
                >
                    <section>
                        <h3>What is Bluesat?</h3>
                        <p className="paragraph">
                            We are a group of UNSW Sydney's most dedicated and brightest young minds
                            in space engineering, currently undertaking three major projects, our
                            satellite tracking ground station, offworld robotics and our high
                            altitude balloon platform. Bluesat started its journey in 1997 as UNSW
                            Sydney's first student project society and has remained active since. We
                            have been supporting all students in the university community to achieve
                            engineering excellence, learn teamwork and grow their confidence.
                        </p>
                    </section>
                    <section>
                        <h2>Our Projects</h2>
                        <h3>Groundstation</h3>
                        <p className="paragraph">
                            From 2017 to 2018, Bluesat built and maintained our K17 satellite
                            tracking ground station as support for duration of the UNSW EC0
                            (QB50-AU02) cubesat mission, maintaining a mission critical data
                            connection. Now, as a part of the development of our portable satellite
                            tracking ground station, our team has repeatedly captured and processed
                            data from NOAA satellites to test our capabilities and improve our
                            members' understanding of the scope of this mission. Moving forwards we
                            seek to extend this ground station's capabilities to maintaining and
                            uplink and downlink connection with our high altitude balloon and later
                            our own cubesat in low Earth orbit.
                        </p>
                        <h3>Australian Rover Challenge</h3>
                        <p className="paragraph">
                            Recently, Bluesat has seen the return of our Offworld Robotics project
                            and in 2024 we will be entering our first rover challenge since 2019. In
                            this, we have put together a highly motivated team, most of which have
                            previous experience with competitive rovers, to bring Bluesat back to
                            the peak of competitive robotics. Following the Australian Rover
                            Challenge, we will be targeting the European Rover Challenge once again
                            and University Rover Competition internationally to further develop our
                            platform.
                        </p>
                        <h3>High Altitude Balloon</h3>
                        <p className="paragraph">
                            Previously funded by NATO to carry a synthetic aperture radar as a
                            payload, Bluesat's high altitude balloon launches have returned since
                            the COVID slowdown. With a new team we are starting from the ground up
                            with interest from the University to carry a research payload and
                            targeting various competitive research grants. Our high altitude balloon
                            platform is rapidly coming to fruition and will see it's first flight in
                            the first quarter of 2024 with a 2 hour flight planned, reaching a max
                            altitude of 35 km.
                        </p>
                        <h3>Spaceport America Cup 2023</h3>
                        <p className="paragraph">
                            Despite a brief slowdown over the COVID-19 pandemic and off-campus
                            learning, Bluesat has returned stronger than ever. Demonstrating this to
                            the world was our entrance of a payload in the Spaceport America Cup in
                            the United States; a joint venture with UNSW Rocketry. Our 4U cubesat
                            form factor DLP 3D printer designed to work in microgravity preventing
                            cavitation and be insensitive to pressure changes won us an honourable
                            mention by the judges in a competition where much of the judging was
                            done based on the rockets themselves. Our payload was flown to 30,000 ft
                            and returned safely on UNSW Rocketry's “Archangel”.
                        </p>
                        {/* <div id="showcase-container"> */}
                        {/* </div> */}
                        {/* Implement later. */}
                    </section>
                </main>
            </div>
            <Footer />
        </>
    );
}
