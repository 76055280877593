import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import Index from "./landing";
import Contact from "./contact";
import NotFound from "./404";

import { BrowserRouter, Routes, Route } from "react-router-dom";

reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById("root")!); // Shouldn't be null ever. Don't regress.
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={ <Index /> } />
            <Route path="/contact" element={ <Contact /> } />
            <Route path="/*" element={ <NotFound /> } />
        </Routes>
    </BrowserRouter>
);
