import Helmet from "react-helmet";

import Nav from "./elements/nav";
import Footer from "./elements/footer";

export default function Contact()
{
    return (
        <>
            <Helmet>
                {/* HTML Meta Tags */}
                <title>Contact Us | Bluesat UNSW</title>
                <meta name="description" content="Get in touch with us!" />

                {/* OpenGraph Meta Tags */}
                <meta property="og:url" content="https://bluesat.unsw.edu.au" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Contact Us | Bluesat UNSW" />
                <meta property="og:description" content="Get in touch with us!" />
                <meta property="og:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="bluesat.unsw.edu.au" />
                <meta property="twitter:url" content="https://bluesat.unsw.edu.au" />
                <meta name="twitter:title" content="Contact Us | Bluesat UNSW" />
                <meta name="twitter:description" content="Get in touch with us!" />
                <meta name="twitter:image" content="https://bluesat.unsw.edu.au/static/media/bluesat_logo_wb.png" />

            </Helmet>
            <Nav />
            <main
                style=
                {{
                    flex: 1,
                    width: "100%",
                    height: "auto",
                    padding: "80px clamp(10px, calc(50% - 250px), 200px) 10px clamp(10px, calc(50% - 250px), 200px)",
                    margin: 0
                }}
            >
                <h3>Get in touch!</h3>
                <p>
                    Email us at:
                    <br />
                    <a href="mailto:bluesat@unsw.edu.au">bluesat@unsw.edu.au</a>
                </p>
            </main>
            <Footer />
        </>
    );
}